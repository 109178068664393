import React, { useState } from 'react'
import Popup from './UI/Popup'
import Label from './UI/Label'
import { useForm } from 'react-hook-form'
import Button from './UI/Button'

export default function ContactForm({ company, phone, send, close }) {
  const [sent, setSent] = useState(false)
  const { getValues, register, errors } = useForm({ mode: 'onChange' })
  const trigger = () => {
    send(getValues())
    setSent(true)
    setTimeout(() => { setSent(false); close() }, 6000)
  }
  if (sent) 
    return (
      <Popup title="Message envoyé" close={close}>
        <img className="rounded shadow w-full" src="https://media0.giphy.com/media/g9582DNuQppxC/giphy.gif" />
        <div className="text-center mt-4">
          Votre demande a bien été envoyée !
          <br />
          On revient vers vous très vite.
        </div>
      </Popup>
    )
  return (
    <Popup title={`Contactez ${company}`} close={close}>
      <div className="">
        <div className="mb-4 font-thin">Par téléphone au {phone} ou par message :</div>
        <div className="flex w-full flex-wrap">
          <Label title="Prénom">
            <input
              className="form-input w-full"
              type="text"
              name="firstname"
              ref={register({ required: true })} />
          </Label>
          <Label title="Nom">
            <input
              className="form-input w-full"
              type="text"
              name="lastname"
              ref={register({ required: true })} />
          </Label>
        </div>
        <div className="flex w-full flex-wrap">
          <Label title="Email">
            <input
              className="form-input w-full"
              type="text"
              name="email"
              ref={register({ required: true, pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ })} />
          </Label>
          <Label title="Téléphone">
            <input
              className="form-input w-full"
              type="text"
              name="phone"
              ref={register({ required: true, pattern: /^(?:(?:\+|00)33|32|352|41|0)\s*[1-9](?:[\s]*\d{2}){4}$/ })} />
          </Label>
        </div>
        <Label title="Description" className="lg:w-full">
          <textarea
            className="form-input w-full"
            type="text"
            name="description"
            ref={register({ required: true })} />
        </Label>
      </div>
      <Button onClick={trigger}>Envoyer</Button>
    </Popup>
  )
}
